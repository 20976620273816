<div class="flex flex-col justify-center">
    <app-service-provider-form #form />
    <button class="btn btn-block btn-primary max-w-64 mx-auto mb-4" (click)="create()" [disabled]="!form.valid() || creatingFlag"><span class="material-symbols-outlined">
        add_circle
        </span>
        @if(creatingFlag) {
            <app-loading />
        }
         Create</button>
</div>
<app-toast #errorToast type="error" />