<svg xmlns="http://www.w3.org/2000/svg" width="44" height="39" viewBox="0 0 44 39">
    <rect id="Rectangle_176" data-name="Rectangle 176" width="26" height="18" transform="translate(6 6)" fill="#069bb4"/>
    <path id="Icon_material-chat" data-name="Icon material-chat" d="M35.4,3H6.6A3.6,3.6,0,0,0,3.018,6.6L3,39l7.2-7.2H35.4A3.611,3.611,0,0,0,39,28.2V6.6A3.611,3.611,0,0,0,35.4,3ZM10.2,15.6H31.8v3.6H10.2Zm14.4,9H10.2V21H24.6Zm7.2-10.8H10.2V10.2H31.8Z" transform="translate(-3 -3)" fill="#fafafa"/>
    <g *ngIf="count > 0" id="Group_105" data-name="Group 105" transform="translate(19.91 15)">
        <circle id="Ellipse_19" data-name="Ellipse 19" cx="12" cy="12" r="12" transform="translate(0.09)" fill="#bf4114"/>
        <text *ngIf="count < 10" id="cnt" data-name="cnt" transform="translate(12.09 17)" fill="#060518" font-size="14" font-family="Raleway-Bold, Raleway" font-weight="700"><tspan x="-3.75" y="-0.5">{{count}}</tspan></text>
        <text *ngIf="count >= 10 && count < 100" id="cnt" data-name="cnt" transform="translate(12.09 17)" fill="#060518" font-size="14" font-family="Raleway-Bold, Raleway" font-weight="700"><tspan x="-7.455" y="-0.5">{{count}}</tspan></text>
        <text *ngIf="count >= 100" id="cnt" data-name="cnt" transform="translate(12.09 17)" fill="#060518" font-size="12" font-family="Raleway-Bold, Raleway" font-weight="700"><tspan x="-8" y="-1.5">99+</tspan></text>
    </g>
</svg>
  