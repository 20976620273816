<div class="prose mx-auto">
  <div [@routeAnimations]="getRouteAnimationData()">
    <router-outlet></router-outlet>
  </div>
  <div class="h-20"></div>
</div>

<div class="btm-nav bg-primary btm-nav-lg">
  <button class="max-h-3/4" routerLink="/">
    <img class="justify-self-end" src="assets/searchbutton.svg" />
  </button>
  <button class="max-h-3/4" routerLink="settings/financial">
    <img src="assets/moneybutton.svg" />
  </button>
  <button class="max-h-3/4 h-3/4" routerLink="settings">
    <img class="max-h-3/4 h-3/4" src="assets/gears.svg" />
  </button>
  <button class="max-h-full" routerLink="profile">
    @if(userService.profilePhoto()) {
      <app-avatar size="md" [path]="userService.profilePhoto()" />
    } @else {
      <img class="max-w-16" src="assets/profilebutton.svg" />
    }
  </button>
</div>