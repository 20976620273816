import { Component } from '@angular/core';

@Component({
  selector: 'app-money',
  standalone: true,
  imports: [],
  templateUrl: './money.page.html',
  styleUrl: './money.page.scss'
})
export class MoneyPage {

}
