<app-back-button />
<app-loading-error-block [data]="sp" [error]="error" />
@if(sp) {
    <div class="flex flex-col items-center p-4 gap-4">
        <div class="flex flex-row gap-4 items-center">
            <h2 class="my-0">Reviews for {{ sp.display_name }}</h2>
            <button class="btn btn-primary" routerLink="/review/create/provider/{{ sp.id }}">Leave a Review</button>
        </div>
        @for (review of sp.reviews; track $index) {
            <app-review-badge class="w-full" [detailId]="review.id" [owner]="review.user!" [review]="review" />
        }
    </div>
}