<div class="flex flex-col h-screen">
  <div class="flex-auto flex-row bg-white place-content-center">
    <div>
      <video class="max-w-full"
        height="auto"
        src="assets/AnimatedLogo.mp4"
        autoplay
        playsinline
        [muted]="true"
        onloadedmetadata="this.muted=true">
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</div>